import React, { useContext } from 'react';
import SEO from '@components/Common/SEO/index';
import FooterV2 from '@components/FooterV2';
import Navigation from '@components/Navigation';
import { graphql } from 'gatsby';
import { GlobalStateContext } from '@src/context/GlobalContextProvider';
import CrioSATMockInterview from './CrioSATMockInterview';
import HeroInfo from './HeroInfo';
import LeadGenerationOpenForm from '@components/LeadGenerationForm/LeadGenerationOpenForm';
import ReportSummarySection from './ReportSummarySection';
import WhyTakeThisSection from './WhyTakeThisSection';
import HowItWorks from './HowItWorks';
import RealFeedback from './RealFeedback';
import FAQSection from '@components/v5/FAQSection/index';
import MockInterviewCTA from './MockInterviewCTA';
import PlacementOutcomes from './PlacementOutcomes';
import AboutSection from './AboutSection';

const faqs = [
  {
    question: 'Is the Crio-SAT mock interview free?',
    answer: 'Yes, the Crio-SAT mock interview is completely free.',
  },
  {
    question: 'How do I get the assessment report?',
    answer:
      'After completing the 60-minute mock interview, you can request your personalized assessment report through the CTA button.',
  },
  {
    question: 'What happens after the test?',
    answer:
      "After the test, you'll receive a detailed AI-generated assessment report and have the option to book a 1:1 session with an expert for further guidance.",
  },
  {
    question: 'How long does the mock interview take?',
    answer: 'The mock interview takes 60 minutes to complete.',
  },
  {
    question: 'Can I take the Crio-SAT mock interview multiple times?',
    answer: 'Yes, you can request our Program Advisor for the same.',
  },
  {
    question: 'What skills are assessed in the Crio-SAT mock interview?',
    answer:
      'The mock interview evaluates key skills in 4 key domains: MERN (Full-stack), Backend (Java), Quality Assurance, and Data Analytics.',
  },
  {
    question: 'Will I get placement after completing the Crio-SAT?',
    answer:
      'No, completing the Crio-SAT does not guarantee placement. However, it offers a simulated experience of a real tech interview, helping you gain valuable insights and better prepare for actual job interviews.',
  },
];

export default function SkillAssessmentPage(props) {
  const globalState = useContext(GlobalStateContext);
  const savedInterviewData = globalState.interviewData;
  console.log(
    '🚀 ~ SkillAssessmentPage ~ savedInterviewData:',
    savedInterviewData,
  );

  return (
    <div>
      <div className="mx-auto w-full md:max-w-[1200px]">
        <SEO
          canonicalUrl={'https://crio.do/placements'}
          title={'Crio Skill Assessment'}
          description={'Book your mock interview slot with Crio'}
        />
        <div className="relative mx-4 h-fit max-w-[1300px] pb-20 md:mx-auto">
          <div className="fixed left-0 top-0 z-[1001] w-screen">
            <Navigation hideTopStrip hideCTA />
          </div>
          {savedInterviewData ? (
            <>
              <CrioSATMockInterview interviewData={savedInterviewData} />
            </>
          ) : (
            <>
              <div className="mt-[80px] flex h-full flex-col gap-10 md:h-[600px] md:flex-row">
                <HeroInfo />
                <div>
                  <LeadGenerationOpenForm
                    isOpenForm={true}
                    hasProgramInterestedField
                    wrapperClassName={'flex justify-center [&>div]:h-[545px]'}
                    pageOneButtonText={'Take Free Mock Interview'}
                    pageTwoButtonText={'Take Free Mock Interview'}
                  />
                </div>
              </div>
              <ReportSummarySection />
              <WhyTakeThisSection />
              <HowItWorks />
              <AboutSection />
              <PlacementOutcomes />
              <RealFeedback />
              <FAQSection data={faqs} postContent={null}></FAQSection>
              <MockInterviewCTA />
              {/* <HomepageHero program={null} showOpenForm /> */}
            </>
          )}
        </div>
      </div>
      <FooterV2 />
    </div>
  );
}

export const pageQuery = graphql`
  query {
    strapiPrograms(PrimaryProgramID: { eq: "FREE_TRIAL" }) {
      ...strapiProgramsFragment
    }
  }
`;
