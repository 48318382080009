import React from 'react';

export default function ReportSummarySection() {
  const highlights = [
    <>
      Comprehensive score breakdown <strong>across core tech skills.</strong>
    </>,
    <>
      <strong>AI-Driven feedback</strong> on strengths & key improvement areas.
    </>,
    <>
      Detailed <strong>evaluation of coding and technical efficiency</strong>{' '}
      while identifying concept gaps.
    </>,
    <>
      Get <strong>personalized career strategy</strong> and expert advice on job
      readiness and interviews.
    </>,
  ];

  return (
    <section className="flex w-full  gap-4 bg-white py-10  ">
      <div className="mx-auto flex max-w-7xl flex-col items-center gap-10 text-center md:flex-row-reverse md:text-left">
        {/* Text Content */}
        <div>
          <h2 className="mb-6 font-manrope text-[20px] font-extrabold text-v5-neutral-600 md:text-[40px]">
            What You’ll Get In Your Report
          </h2>
          <p className="mb-6 font-manrope text-[16px] font-extrabold text-v5-neutral-500 md:text-[24px]">
            Get a detailed, Personalized Report that includes:
          </p>
          <ul className="list-inside list-disc space-y-4 pl-0 text-left text-v5-neutral-500">
            {highlights.map((item, idx) => (
              <li
                key={idx}
                className="font-rubik text-[10px] font-normal md:text-[16px]"
              >
                {item}
              </li>
            ))}
          </ul>
        </div>

        {/* Image Section */}
        <div className="flex flex-col items-center gap-4">
          <img
            src={'/images/Section2.webp'}
            alt="Next"
            className="w-full object-contain md:w-[470px]"
          />
        </div>
      </div>
    </section>
  );
}
