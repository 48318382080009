import React, { useEffect, useState } from 'react';
import { format } from 'date-fns';

export default function TestScheduleCountdown({
  activationTime,
  interviewLink,
}) {
  const [timeLeft, setTimeLeft] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  const activationDate = activationTime ? new Date(activationTime) : null;
  const isValidDate = activationDate instanceof Date && !isNaN(activationDate);

  const formattedTime = isValidDate
    ? format(activationDate, 'hh:mm aa')
    : 'Invalid time';
  const formattedDate = isValidDate
    ? format(activationDate, 'EEEE, dd MMMM')
    : '';

  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    const countdown = setInterval(() => {
      const now = new Date().getTime();
      const start = new Date(activationTime).getTime();
      const diff = start - now;

      if (diff <= 0) {
        clearInterval(countdown);
        setTimeLeft({ days: 0, hours: 0, minutes: 0, seconds: 0 });
        setIsActive(true);
      } else {
        setTimeLeft({
          days: Math.floor(diff / (1000 * 60 * 60 * 24)),
          hours: Math.floor((diff / (1000 * 60 * 60)) % 24),
          minutes: Math.floor((diff / (1000 * 60)) % 60),
          seconds: Math.floor((diff / 1000) % 60),
        });
      }
    }, 1000);

    return () => clearInterval(countdown);
  }, [activationTime]);

  return (
    <div className="w-full px-6 py-6 text-center md:py-12">
      <h2 className="mb-6 font-manrope text-[24px] font-bold md:text-[40px]">
        Your Test is Scheduled for {formattedTime}
      </h2>

      {isActive ? (
        <a
          href={interviewLink}
          target="_blank"
          rel="noopener noreferrer"
          className="mx-auto flex h-[56px] w-[300px] items-center whitespace-nowrap rounded-[10px] border-2 border-v5-yellow-200 bg-v5-yellow-200 py-4 px-8 font-manrope text-base font-bold capitalize text-black shadow-lg shadow-v5-yellow-200/50 hover:shadow-xl hover:shadow-v5-yellow-100/50"
        >
          Start Your Free Mock Interview
          {/* <ChevronRight className="h-4 w-4" /> */}
        </a>
      ) : (
        <div className="mx-auto inline-flex max-w-4xl flex-col items-center justify-between gap-2 rounded-2xl bg-v5-yellow-200 px-14 py-4 text-black md:flex-row md:gap-6 md:px-6">
          <div className="font-rubik text-[8px] md:text-[24px]">
            Test Starts at{' '}
            <span className="font-bold">
              {formattedDate}, {formattedTime}
            </span>
          </div>
          <div className="flex items-center gap-3 text-center font-rubik text-[14px] font-medium">
            <div>
              <div className="text-[14px] md:text-[24px]">
                {String(timeLeft.days).padStart(2, '0')}
              </div>
              <div className="text-[6px] md:text-[10px]">Days</div>
            </div>
            <div>:</div>
            <div>
              <div className="text-[14px] md:text-[24px]">
                {String(timeLeft.hours).padStart(2, '0')}
              </div>
              <div className="text-[6px] md:text-[10px]">Hours</div>
            </div>
            <div>:</div>
            <div>
              <div className="text-[14px] md:text-[24px]">
                {String(timeLeft.minutes).padStart(2, '0')}
              </div>
              <div className="text-[6px] md:text-[10px]">Mins</div>
            </div>
            <div>:</div>
            <div>
              <div className="text-[14px] md:text-[24px]">
                {String(timeLeft.seconds).padStart(2, '0')}
              </div>
              <div className="text-[6px] md:text-[10px]">Sec</div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
