import React from 'react';

const steps = [
  {
    step: 'Step:01',
    title: 'Select domain',
    description:
      'Select domain (FullStack, Backend, QA Automation, Data Analytics)',
    icon: '/images/Icon1.webp',
  },
  {
    step: 'Step:02',
    title: 'Take the mock interview',
    description: 'Take the 30-min online mock interview',
    icon: '/images/Icon2.webp',
  },
  {
    step: 'Step:03',
    title: 'Receive your report',
    description: 'Receive your AI-analyzed report instantly',
    icon: '/images/Icon3.webp',
  },
  {
    step: 'Step:04',
    title: 'Use the insights',
    description:
      'Use the insights to fix gaps or enroll in our targeted training program',
    icon: '/images/Icon4.webp',
  },
];

export default function HowItWorks() {
  return (
    <section className="w-full bg-white py-12 text-center md:py-20">
      <h2 className="mb-12 text-3xl font-bold md:text-4xl">How It Works</h2>
      <div className="flex flex-col items-center gap-6 md:flex-row md:justify-between md:gap-8">
        {steps.map((step, index) => (
          <div
            key={index}
            className="flex w-full max-w-xs flex-col items-center rounded-3xl bg-[#F4FBF9] p-6 text-center shadow-sm"
          >
            <img
              src={step.icon}
              alt={step.title}
              className="mb-4 h-16 w-16 md:h-20 md:w-20"
            />
            <h3 className="mb-2 font-bold md:text-[20px]">{step.step}</h3>
            <p className="text-sm text-gray-600 md:text-[16px]">
              {step.description}
            </p>
          </div>
        ))}
      </div>
    </section>
  );
}
