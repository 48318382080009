import React from 'react';

export default function PlacementOutcomes() {
  return (
    <section className="mx-auto max-w-6xl rounded-2xl bg-white px-6 py-10 text-center shadow-lg">
      <div className="flex flex-col items-center justify-between md:flex-row">
        <h2 className="mb-6 font-manrope text-2xl text-[20px] font-bold md:text-[40px]">
          Placement
          <br className="hidden md:block" />
          Outcomes
        </h2>

        <div className="mb-8 flex flex-row items-center justify-center gap-3 md:gap-12">
          <div>
            <p className="font-manrope text-[30px] font-bold md:text-[60px]">
              95%
            </p>
            <p className="font-rubik text-[10px] text-v5-neutral-600 md:text-[16px]">
              Placed Graduates
            </p>
          </div>

          <div className="hidden h-10 w-px bg-gray-300 md:block" />

          <div>
            <p className="font-manrope text-[30px] font-bold md:text-[60px]">
              1000+
            </p>
            <p className="font-rubik text-[10px] text-v5-neutral-600 md:text-[16px]">
              Hiring Partners
            </p>
          </div>

          <div className="hidden h-10 w-px bg-gray-300 md:block" />

          <div>
            <p className="font-manrope text-[30px] font-bold md:text-[60px]">
              81%
            </p>
            <p className="font-rubik text-[10px] text-v5-neutral-600 md:text-[16px]">
              Average Salary Hike
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}
