import React from 'react';

const testimonials = [
  {
    text: 'Crio-SAT really showed me where I stand in tech—super insightful and a great reality check!',
  },
  {
    text: 'This test gave a great glimpse into real interview scenarios, and the personalized assessment report was the best part!',
  },
];

export default function RealFeedback() {
  return (
    <section className="py-12 text-center md:py-20 md:text-left">
      <h2 className="mb-4 text-3xl font-bold md:text-4xl">
        Real Feedback. Real Growth
      </h2>

      <p className="mb-6 text-base text-gray-700 md:text-lg">
        These are just a few examples of the{' '}
        <span className="font-semibold text-black">practical feedback</span>{' '}
        you’ll get. This isn’t a test score—
        <span className="font-semibold text-green-600">
          {' '}
          it’s a career upgrade plan!
        </span>
      </p>

      <div className="flex flex-col gap-6 md:flex-row md:justify-center">
        {testimonials.map((item, index) => (
          <div
            key={index}
            className="flex items-start gap-4 rounded-2xl bg-white p-6 shadow-lg"
          >
            <span className="text-[80px] leading-none text-green-600">“</span>
            <p className="text-left text-gray-800 md:text-[24px]">
              {item.text}
            </p>
          </div>
        ))}
      </div>
    </section>
  );
}
